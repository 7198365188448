import React, { useState } from "react";

const ShowMoreText = ({ initialText, maxLength, className }) => {
  const [showFullText, setShowFullText] = useState(false);

  // Function to toggle between showing full text and truncated text
  const toggleText = () => {
    setShowFullText(!showFullText);
  };

  // Truncate the text if it exceeds maxLength characters
  const truncatedText = initialText?.slice(0, maxLength);

  return (
    <div className={className}>
      {showFullText ? (
        <span onClick={toggleText}>{initialText}</span>
      ) : (
        <span onClick={toggleText}>
          {truncatedText}
          {initialText?.length > maxLength && "..."}
        </span>
      )}
    </div>
  );
};

export default ShowMoreText;
