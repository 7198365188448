import React, { useState, useContext, useEffect } from "react";
import "./Verification.scss";
import { PhoneInput } from "../../../components/phoneInput/PhoneInput";
import { checkPhoneValidity } from "../../../core/apis/users";
import { useGlobalLangValues } from "../../../context/LanguageContext";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as yup from "yup";
import { FormInput } from "../../../components/formComponents/FormComponents";
import {
  Radio,
  FormControlLabel,
  RadioGroup,
  Button as MUIButton,
} from "@mui/material";
import moment from "moment";
import Countdown from "react-countdown";
//Icons
import EmailIcon from "../../../assests/Images/email.png";
import PhoneIcon from "../../../assests/Images/telephone.png";
import WhatsappIcon from "../../../assests/Images/greenwhatsapp.png";
import SmsIconInactive from "../../../assests/Images/smsinactive.png";
import WhatsappIconInactive from "../../../assests/Images/whatsappinactive.png";
import SmsIcon from "../../../assests/Images/SMS.png";
import otploader from "../../../assests/Images/otp-loader.png";
import * as OTP from "../../../core/apis/PhoneVerification";
import { AlertContext } from "../../../context/AlertContext";
import Image from "../../../components/image/Image";
import { useCookies } from "react-cookie";
import Button from "../../../components/button/Button";
import { useGlobalValues } from "../../../context/GlobalContext";
import OtpInput from "react-otp-input";
import { Tooltip, tooltipClasses, styled } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import informationSvg from "../../../assests/Images/information.png";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const VerificationStep = (props) => {
  const { onSuccess, response } = props;
  const [cookies, setCookies] = useCookies(["user_info", "user", "nlog"]);

  // State variables
  const [verificationMethod, setVerificationMethod] = useState("email");
  const [verificationType, setVerificationType] = useState("whatsapp");
  const [sendTypeId, setSendTypeId] = useState(1);
  const [dialCode, setDialCode] = useState("");
  const [phone_number_error, setPhone_number_error] = useState("");
  const [otp, setOtp] = useState({ otp: "", id: "" });
  const [saveLoading, setSaveLoading] = useState(false);
  const [sendingLaoding, setSendingLaoding] = useState(false);
  const [showOtpForm, setshowOTPform] = useState(false);
  const [time, setTime] = useState("");

  const { thirdPartyValue } = useGlobalValues();

  // Localization and translation
  const { t } = useTranslation();
  const { isArabic } = useGlobalLangValues();

  // Context
  const { setAlert } = useContext(AlertContext);

  // Check if Du integration is enabled
  let DuIntegration = thirdPartyValue == 2;

  // Validation schema using Yup
  const verficationSchema = yup.object({
    email:
      sendTypeId == 1
        ? yup.string().email("Invalid email").required("Email is required")
        : yup.string().email("Invalid email").nullable(),
    phone_number:
      sendTypeId != 1
        ? yup.number().nullable().required("Phone number is required")
        : yup.number().nullable(),
  });

  // Formik form instance
  const formik = useFormik({
    initialValues: {
      email: "",
      phone_number: null,
    },
    enableReinitialize: true,
    validationSchema: verficationSchema,
    onSubmit: (values) => {
      handleSubmitForm(values);
    },
  });

  const { resetForm } = formik;

  // Check phone number validity
  const checkPhone = async (phone_number) => {
    let response = false;
    if (!phone_number) {
      setPhone_number_error(t("Field is required"));
      response = false;
    } else {
      await checkPhoneValidity({ phone_number: phone_number }).then((res) => {
        if (res?.data?.data) {
          setPhone_number_error("");
          response = true;
        } else {
          setPhone_number_error(t("Invalid phone number"));
          response = false;
        }
      });
    }

    return response;
  };

  // Handler for form submission
  const handleSubmitForm = async (formValues) => {
    setSaveLoading(true);
    setSendingLaoding(true);
    try {
      if (sendTypeId !== 1) {
        const phonecheck = await checkPhone(formValues.phone_number);
        if (phonecheck) {
          const response = await OTP.sendOTP({
            phone_number: DuIntegration
              ? dialCode + formik.values.phone_number
              : formik.values.phone_number,
            email: formik.values.email,
            send_type_id: sendTypeId,
          });

          handleOTPResponse(response);
        } else {
          setSaveLoading(false);
        }
      } else {
        const response = await OTP.sendOTP({
          phone_number: DuIntegration
            ? dialCode + formik.values.phone_number
            : formik.values.phone_number,
          email: formik.values.email,
          send_type_id: sendTypeId,
        });

        handleOTPResponse(response);
      }
    } catch (error) {
      setAlert({
        visible: true,
        type: "error",
        text: t("An error occurred while processing your request."),
      });
    } finally {
      setSaveLoading(false);
    }
  };

  // Handler for OTP response
  const handleOTPResponse = (response) => {
    const success = response?.data?.success;
    const message = t(response?.data?.message);

    setAlert({
      visible: true,
      type: success ? "success" : "error",
      text: message,
    });

    if (success) {
      setTimeout(() => {
        setSendingLaoding(false);
        setshowOTPform(true);
      }, 1000);
    } else {
      setshowOTPform(false);
      setSendingLaoding(false);
    }
  };

  // Handler for changing phone number
  const handleNumber = () => (number, info) => {
    setDialCode(info?.dialCode);
    formik.setFieldValue("phone_number", number);
    setPhone_number_error("");
  };

  //select whether verifying by OTP or by Email
  const handleRadio = (e) => {
    setSendTypeId(+e.target.value);
    if (e.target.value == 3) {
      setVerificationType("whatsapp");
    } else if (e.target.value == 2) {
      setVerificationType("sms");
    }
  };

  // Handler for verification method (Email or Phone)
  const handleVerfication = (e) => {
    setVerificationMethod(e.target.value);
    if (e.target.value == "email") {
      setSendTypeId(1);
    } else {
      setSendTypeId(3);
    }
  };

  // Handler for changing Email or Phone value
  const handleChangeNumber = () => {
    setshowOTPform(false);
    setSendingLaoding(false);
    setSaveLoading(false);
  };

  // Handler for changing verification method (Email or Phone)
  const handleChangeVerficationMethod = () => {
    setshowOTPform(false);
    setSendingLaoding(false);
    setSaveLoading(false);
    setVerificationMethod("email");
    setSendTypeId(1);
    resetForm();
  };

  // Handler for resending OTP
  const handleResend = () => {
    let payload = {
      phone_number: DuIntegration
        ? dialCode + formik.values.phone_number
        : formik.values.phone_number,
      email: formik.values.email,
      send_type_id: sendTypeId,
    };
    //send instead of resend
    OTP.sendOTP(payload).then((res) => {
      setAlert({
        visible: true,
        type: !res.data.success ? "error" : "success",
        text: t(res.data.message),
      });
      setTime(res?.data?.data?.time);
    });
  };

  const handleVerifyOtp = async () => {
    await OTP.verifyOTP({
      phone_number: DuIntegration
        ? dialCode + formik.values.phone_number
        : formik.values.phone_number,
      otp: otp.otp,
      email: formik.values.email,
      send_type_id: sendTypeId,
    }).then(async (response) => {
      setAlert({
        visible: true,
        type: !response?.data?.success ? "error" : "success",
        text: t(response?.data?.message),
      });
      if (response?.data?.success) {
        let payload = {
          phone_number: DuIntegration
            ? dialCode + formik.values.phone_number
            : formik.values.phone_number,
          otp: otp.otp,
          email: formik.values.email,
          send_type_id: sendTypeId,
          dialCode: dialCode,
        };
        response = true;
        onSuccess({ verficationstep: payload }); // Assuming onSuccess is passed as a prop
      }
    });
  };

  // Timer component for countdown
  let timer = (
    <Countdown
      date={moment(time) + 120000 || ""}
      zeroPadTime={1}
      daysInHours
      precision={2}
      renderer={(props) => ` (${props.minutes}:${props.seconds}s)`}
      onComplete={() => setTime(0)}
    />
  );

  return (
    <div className={`verification-step ${isArabic ? "arabic-component" : ""} `}>
      {!sendingLaoding && !showOtpForm ? (
        <>
          <h2>{t("Let’s get started")}</h2>
          <h3>{t("Please enter your registered email or mobile number for verification")}</h3>
          <form onSubmit={formik.handleSubmit}>
            <div className="verification-method">
              <RadioGroup
                row
                className="RadioGroup width100"
                aria-labelledby="email-or-otp"
                name="email-or-otp"
                value={verificationMethod}
                onChange={handleVerfication}
                style={{ marginLeft: "0px", marginRight: "0px" }}
              >
                <FormControlLabel
                  label={
                    <div>
                      <Image
                        src={EmailIcon}
                        className={
                          isArabic ? "iconImage mlarab-10" : "iconImage mR-10"
                        }
                      />
                      {t("Store Email")}
                    </div>
                  }
                  control={<Radio />}
                  value="email"
                />

                <FormControlLabel
                  label={
                    <div className="radioInfoIcon">
                      <Image
                        src={PhoneIcon}
                        className={
                          isArabic ? "iconImage mlarab-10" : "iconImage mR-10"
                        }
                      />
                      {t("Employee Mobile Number")}
                      <LightTooltip
                        title={
                          <div className={isArabic ? "arabic-component " : ""}>
                            {t("otpmsg")}
                          </div>
                        }
                        arrow
                        placement={"top"}
                      >
                        <HelpOutlineIcon style={{color:"#4ba4f0" , margin:"0px 5px"}}/>
                      </LightTooltip>
                    </div>
                  }
                  control={<Radio />}
                  value="phone"
                />
              </RadioGroup>
              <div className="verfication-options">
                {/* Conditional rendering based on selected verification method */}
                {verificationMethod === "email" && (
                  <FormInput
                    required
                    type="text"
                    name="email"
                    placeholder={t("Enter Email")}
                    helperText={formik.touched?.email && formik.errors?.email}
                    value={formik?.values?.email}
                    onChange={formik.handleChange}
                    label={t("Employee Email")}
                  />
                )}
                {verificationMethod == "phone" && (
                  <div className="phone-input">
                    <div style={{ width: "100%" }}>
                      <PhoneInput
                        required
                        value={formik.values?.phone_number}
                        onChange={handleNumber}
                        inputlabel={t("Country Code")}
                        inputclassname="label-Phone"
                        className="inputForm direction-left"
                        ip={!DuIntegration}
                        onlyCountries={DuIntegration ? ["lb"] : []}
                        country="lb"
                        enableAreaCodes={!DuIntegration}
                        disableCountryCode={DuIntegration}
                        disableDropdown={DuIntegration}
                        helperText={
                          phone_number_error !== ""
                            ? phone_number_error
                            : formik.touched?.phone_number &&
                              formik.errors?.phone_number
                        }
                      />
                    </div>
                    <div className="verify-by">{t("Verify by")}</div>
                    {/* Radio group for selecting verification type */}
                    <div className="verification-options">
                      <RadioGroup
                        row
                        className="RadioGroup"
                        aria-labelledby="email-or-otp"
                        name="email-or-otp"
                        value={sendTypeId}
                        onChange={handleRadio}
                      >
                        <FormControlLabel
                          label={
                            <div>
                              <Image
                                src={
                                  verificationType === "whatsapp"
                                    ? WhatsappIcon
                                    : WhatsappIconInactive
                                }
                                className={
                                  isArabic
                                    ? "iconImage mlarab-10"
                                    : "iconImage "
                                }
                              />
                              <span> {t("whatsapp")}</span>
                            </div>
                          }
                          control={<Radio />}
                          value={3}
                        />
                        {/* SMS option (only if country code is LB OR AED) */}
                        {cookies.nlog.country_code &&
                          cookies.nlog.country_code == "LB" && (
                            <FormControlLabel
                              label={
                                <div>
                                  <Image
                                    src={
                                      verificationType === "sms"
                                        ? SmsIcon
                                        : SmsIconInactive
                                    }
                                    className={
                                      isArabic
                                        ? "iconImage mlarab-10"
                                        : "iconImage "
                                    }
                                  />
                                  <span> {t("sms")} </span>
                                </div>
                              }
                              control={<Radio />}
                              value={2}
                            />
                          )}
                      </RadioGroup>
                    </div>
                  </div>
                )}
                <Button
                  className="placeorder"
                  name={t("Submit")}
                  selected
                  type="submit"
                  disabled={saveLoading}
                  loading={saveLoading}
                  width="100%"
                />
              </div>
            </div>
          </form>
        </>
      ) : (
        // Display OTP verification form
        <div className="otp-form">
          <span className="otp-title">{t("OTP Verification")}</span>
          <p className="otp-sub-title">
            {t("An OTP has been sent to your")}
            {sendTypeId != 1 ? t("Mobile Number") : t("Email")}
            <span class="mobile-number">
              {sendTypeId != 1
                ? formik.values.phone_number
                : formik.values.email}
            </span>
          </p>
          {/* Display loading or OTP input based on state */}
          {sendingLaoding && !showOtpForm ? (
            <Image src={otploader} className="otp-image rotate-image" />
          ) : (
            <>
              <span className="otp-text">{t("Verification Code")}</span>
              <div className="form-modalotp">
                <OtpInput
                  shouldAutoFocus
                  value={otp.otp}
                  onChange={(otp) => setOtp({ otp: otp })}
                  numInputs={4}
                  containerStyle="otpContainer"
                  inputStyle="otpInput"
                  focusStyle="otpFocus"
                  isInputNum
                />
              </div>
              <MUIButton
                disabled={Boolean(time)}
                onClick={handleResend}
                className="sendOTP"
              >
                {sendTypeId === 1
                  ? t("Resend Email")
                  : sendTypeId === 2
                  ? t("Resend OTP")
                  : t("Resend OTP")}
                <b> {time ? timer : null}</b>
              </MUIButton>
            </>
          )}
          <Button
            className="placeorder"
            name={showOtpForm ? t("Verify") : t("Sending") + t("....")}
            selected
            onClick={() => handleVerifyOtp()}
            type="submit"
            disabled={saveLoading}
            loading={saveLoading}
            width="50%"
          />
          {/* Change phone number and change verification method links */}
          <div className="otp-text-wrap">
            <p className="otp-text" onClick={() => handleChangeNumber()}>
              {t("Change Phone Number?")}
            </p>
            <p
              className="otp-text"
              onClick={() => handleChangeVerficationMethod()}
            >
              {t("Change Verification Method?")}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default VerificationStep;
