import { api } from "./main";
export const getSales = (payload, page) => {
  return api
    .get(`retailer/get-sell-transactions?page=${page}`, { params: payload })
    .then((res) => res?.data?.data)
    .catch((err) => console.error(err));
};

export const getSalesDetailsById = (id) => {
  return api
    .get(`retailer/get-transaction-by-id/${id}`)
    .then((res) => res.data.data)
    .catch((err) => console.error(err));
};

export const senddisclaimer = (data) => {
  return api
    .post(`send-text`, data)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const attachdisclaimer = (data) => {
  return api
    .post(`attach-file`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
};
export const getTransactions = async (payload) => {
  return await api
    .get(`/retailer/export-transactions`, {
      params: { ...payload },
      responseType: "blob",
    })
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const verifyImei = async (payload) => {
  return await api
    .get(`retailer/imei/check/${payload}`)
    .then((res) => res)
    .catch((err) => console.error(err));
};
